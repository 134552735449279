import '../../sass/components/blocks/hello.scoped.scss';
import React from 'react';
import Finger from '../../icons/finger.svg';
import { Link } from 'gatsby';
import { useModal } from '../../context/ModalContext';
import { StaticImage } from 'gatsby-plugin-image';
import QuickMessageWidget from '../widgets/QuickMessageWidget';
import BgNatureEl from '../backgrounds/bg-nature.el';

const Hello = ({
  buttonText = 'Оставить заявку',
  buttonType,
  children = (
    <>
      <h1>Вентиляция и кондиционирование</h1>
      <h2>ТОО INVEST GROUP COMPANY KZ</h2>
      <h3>
        Профессиональное <span className='green'>изготовление</span> вентиляции
        и кондиционирования с<span className='green'> доставкой</span> по всему
        Казахстану
      </h3>
      <h4>Проектирование, изготовление, монтаж</h4>
    </>
  ),
}) => {
  const { dispatch } = useModal();

  return (
    <BgNatureEl
      Tag='section'
      style={{
        backgroundAttachment: 'fixed',
        borderRadius: '0 0 50% 50% / 0% 0% 60px 60px',
        backgroundClip: 'content-box',
        paddingBottom: '40px',
        overflow: 'hidden'
      }}>
      <div className='hello'>
        <div className='headers'>{children}</div>
        <div className='actions'>
          <div className='actions-btn'>
            {buttonType === 'link-to-main' ? (
              <Link className='btn link-to-main' to='/'>
                <Finger className='icon-finger' />
                <span>{buttonText}</span>
                <span className='decoration'>
                    <StaticImage
                      className='shake'
                      src='../../images/leaves/leaf-3.png'
                      alt='Лист дерева'
                      placeholder='none'
                    />
                  </span>
              </Link>
            ) : (
              <button
                className='btn'
                onClick={() => {
                  dispatch({ type: 'open' });
                }}>
                <Finger className='icon-finger' />
                <span>{buttonText}</span>
                <span className='decoration'>
                    <StaticImage
                      className='shake'
                      src='../../images/leaves/leaf-3.png'
                      alt='Лист дерева'
                      placeholder='none'
                    />
                  </span>
              </button>
            )}
          </div>
          <div className='ventilator'>
              <span className='vent-img'>
                <StaticImage
                  src='../../images/ventilator.png'
                  alt='Вентилятор осевой'
                  placeholder='none'
                />
              </span>
            <span className='vent-blade'>
                <StaticImage
                  src='../../images/blade.png'
                  alt='Диск вентилятора'
                  placeholder='none'
                />
              </span>
            <span className='vent-wind'>
                <StaticImage
                  src='../../images/wind.png'
                  alt='Ветер'
                  placeholder='none'
                />
              </span>
            <span className='decoration decoration-e'>
                <StaticImage
                  className='shake'
                  src='../../images/leaves/leaf-8.png'
                  alt='Лист дерева'
                  placeholder='none'
                />
              </span>
          </div>
          <QuickMessageWidget />
        </div>
        <>
            <span className='decoration decoration-a'>
              <StaticImage
                className='shake'
                src='../../images/leaves/leaf-6.png'
                alt='Лист дерева'
                placeholder='none'
              />
            </span>
          <span className='decoration decoration-b'>
              <StaticImage
                className='shake'
                src='../../images/leaves/leaf-5.png'
                alt='Лист дерева'
                placeholder='none'
              />
            </span>
          <span className='wind'>
              <StaticImage
                className='shake'
                src='../../images/wind.png'
                alt='Ветер'
                placeholder='none'
              />
            </span>
          <span className='decoration decoration-f'>
              <StaticImage
                className='shake'
                src='../../images/leaves/leaf-5.png'
                alt='Лист дерева'
                placeholder='none'
              />
            </span>
        </>
        <>
          <span className='decoration decoration-c'>
            <StaticImage
              className='shake'
              src='../../images/leaves/leaf-1.png'
              alt='Лист дерева'
              placeholder='none'
            />
          </span>
          <span className='decoration decoration-d'>
            <StaticImage
              className='shake'
              src='../../images/leaves/leaf-2.png'
              alt='Лист дерева'
              placeholder='none'
            />
          </span>
        </>
      </div>
    </BgNatureEl>
  );
};

export default Hello;
