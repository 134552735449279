import React from 'react';
import '../sass/pages/404.scoped.scss';
import Layout from '../components/blocks/Layout';
import Seo from '../components/Seo';
import Hello from '../components/blocks/Hello';
import { ModalProvider } from '../context/ModalContext';

const NotFoundPageWrapper = () => {
  return (
    <ModalProvider>
      <NotFoundPage />
    </ModalProvider>
  );
};

const NotFoundPage = () => {
  return (
    <Layout isMainPage={true}>
      <Seo title='404: Страница не найдена' />
      <Hello buttonText='На главную' buttonType='link-to-main'>
        <h2>Вы оказались здесь по ошибке</h2>
        <h1>Cтраницы не существует</h1>
        <h3>
          Можете воспользоваться меню или использовать кнопку что-бы перейти на
          главную страницу
        </h3>
      </Hello>
    </Layout>
  );
};

export default NotFoundPageWrapper;
